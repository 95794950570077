<template>
	<div>
		<app-app-header v-model="indexStore.isHeaderVisible"
						:title="filterPageTitle ?? t('label.competition_calendar')"
						:color="$config.public.colors.primary"
						:breadcrumbs="filterPageTitle ? [{to: $web('events.index'), text: $t('label.competition_calendar')}] : undefined"
						:item-link="{to: '/competition-planner', text: $t('label.competition_planner')}"
						hide-on-scroll/>

		<app-content>
			<app-header-toolbar :on-top="!indexStore.isHeaderVisible" :color="$config.public.colors.primary" flat-on-top-mobile
								:item-link="{to: '/competition-planner', text: $t('label.competition_planner')}">
				<form-input v-model="filterData.search"
							:icon="icons.search"
							type="search"
							@focus="handleSearchInputFocus"
							@blur="handleSearchInputBlur"
							enterkeyhint="search"
							name="search" :placeholder="$t('placeholder.search_competition')"
							inverted hide-details clearable/>

				<template #tools>
					<chip small :icon="icons.sort" @click="switchSort" clickable>{{ selectedSort ? selectedSort.text : $t('label.sorting') }}</chip>

					<div class="border-l flex h-24 pl-8 ml-8 sm:pl-16 sm:ml-16">
						<chip v-if="filterExists" class="mr-8" small clickable @click="resetFilter()">
							<icon small center :icon="icons.reset"/>
						</chip>

						<chip-modal small
									:text="selectedSportsText ?? $t('placeholder.sport_type_id')"
									:title="$t('label.sport_type')"
									:color="selectedSportsText ? 'primary' : null"
									@submit="handleSubmittedFilter(['sport', 'format'])"
									@open="handleOpenedFilter(['sport', 'format'])" class="mr-8" :max-width="200">
							<template v-slot="{deactivate, apply}">
								<cascader-input v-model="selectedSportTypeInput"
												:options="sportTypes"
												value-prop="_key"
												text-prop="title"
												help-prop="description"
												children-prop="formats"
												children-count-prop="formats_count"
												:children-loader="loadSportTypeFormats"
												:unselect="$t('label.any')"
												@selected="apply"/>
							</template>
						</chip-modal>

						<chip-modal small :text="dateText ?? $t('placeholder.date_window')"
									:title="$t('label.time_window')"
									:color="dateText ? 'primary' : null"
									:submit-text="$t('action.filter')"
									@submit="handleSubmittedFilter(['min_date', 'max_date'])"
									@open="handleOpenedFilter(['min_date', 'max_date'])" class="mr-8" :max-width="200" fixed-height>
							<template v-slot="{deactivate}">
								<calendar-input v-model="selectedDateRange"
												:min-date="$date().subtract(1, 'year').toDate()"
												:max-date="$date().add(2, 'years').toDate()"
												type="range"/>
							</template>
							<template v-slot:buttons="{deactivate}">
								<btn v-if="inputFilterData.min_date || inputFilterData.max_date" @click="resetFilter(['min_date', 'max_date'], deactivate)" rounded-large class="flex-grow-x-1">
									<icon :icon="icons.close"/>
									<span>{{ $t('action.reset') }}</span>
								</btn>
							</template>
						</chip-modal>

						<chip-modal small
									:text="selectedLocationText ?? $t('placeholder.region')"
									:title="$t('label.region')"
									:color="selectedLocationText ? 'primary' : null"
									@submit="handleSubmittedFilter(['country', 'state'])"
									@open="handleOpenedFilter(['country', 'state'])" class="mr-8" :max-width="200">
							<template v-slot="{deactivate, apply}">
								<cascader-input v-model="selectedRegionInput"
												:options="countries"
												value-prop="_key"
												text-prop="locale_name"
												image-prop="flag"
												children-prop="states"
												children-count-prop="states_count"
												:children-loader="loadCountryStates"
												:unselect="$t('label.any')"
												@selected="apply"/>
							</template>
						</chip-modal>

						<chip-modal small :text="advancedText ?? `${$t('label.advanced')}...`" :title="$t('label.advanced')"
									:color="filterData.mode ? 'primary' : null"
									:submit-text="$t('action.filter')" @submit="handleSubmittedFilter(['mode'])"
									@open="handleOpenedFilter(['mode'])" class="mr-8" :max-width="200">
							<template v-slot="{deactivate}">
								<div class="px-16">
									<p class="subheading my-16">{{ $t('input.event.attendance_mode') }}</p>

									<btn-group>
										<btn v-for="(option, optionIndex) in eventAttendanceModeOptions"
											 class="flex-grow-x-1"
											 :color="inputFilterData.mode === option.value ? $config.public.colors.primary : null"
											 :key="optionIndex" @click="inputFilterData.mode = option.value">
											{{ option.text }}
										</btn>
									</btn-group>
								</div>
							</template>
							<template v-slot:buttons="{deactivate}">
								<btn v-if="inputFilterData.mode" @click="resetFilter(['mode'], deactivate)" rounded-large class="flex-grow-x-1">
									<icon :icon="icons.close"/>
									<span>{{ $t('action.reset') }}</span>
								</btn>
							</template>
						</chip-modal>
					</div>
				</template>

			</app-header-toolbar>

			<page-content background :full="!showInRow">
				<container :full="!showInRow" class="stretch-layout">
					<div class="flex flex-row-reverse stretch-layout">
						<sticky-content :offset="192" :enabled="showInRow" class="stretch-layout pb-16">
							<map-map ref="mapElement"
									 center-visitor
									 :locate-button="showInRow"
									 :hide-controls="!showInRow"
									 :rounded="showInRow"
									 v-model:fullscreen="showMapFullScreen"
									 v-model:bounds="mapBounds"
									 v-model:zoom="mapZoom"
									 @click="focusMap"
									 @move="focusMap"
									 class="stretch-layout">

								<event-marker v-for="mapEvent in mapModels" :key="mapEvent.id" :event="mapEvent" @click="showEventDetails(mapEvent)"/>

								<map-marker v-for="(mapMarkerGroup, mapMarkerGroupIndex) in mapMarkerGroups" :text="mapMarkerGroup.amount"
											@click="focusMarkerGroup(mapMarkerGroup)"
											no-arrow
											:lat-lng="[mapMarkerGroup.lat, mapMarkerGroup.lng]"
											:key="mapMarkerGroupIndex"/>
							</map-map>
						</sticky-content>

						<responsive-floating-card
							v-model:floating-height="floatingListHeight"
							:floating-min-height="floatingListMinHeight"
							:floating-anchors="floatingListAnchors"
							card-class="stretch-layout w-400 max-w-400 mr-16">
							<card-list-header
								id="events-index"
								:title="t('view.events.competition_calender.title')"
								:description="listHeaderDescription"
								image="widgets/widget-events.png" :ratio="4"/>

							<data-loader url="/v1/events" :params="params" :filter="filterData"
										 :persist-filter-keys="['sport', 'format', 'order', 'search', 'country', 'state', 'min_date', 'max_date', 'mode']"
										 on-scroll as-guest :lazy="isSortingByDistance" @filtering="handleApplyingFilter">

								<template #loading>
									<div v-for="eventLoaderItem in params.limit" class="divided" :key="'event-loader-' + eventLoaderItem">
										<profile-preview-skeleton no-spacing event flat/>
									</div>
								</template>

								<template v-slot="{data: events}">
									<div v-for="(event, eventKey) in events" class="divided">
										<profile-preview :model="event" :to="$app('events-event', {params: {event: event._key}, state: {event_ts: event.cache_buster}})"
														 :key="'event-list-item-' + eventKey"
														 :position="eventKey + 1"
														 list-name="Event Search"
														 no-spacing flat/>
									</div>
								</template>

								<template #missingsearch>
									<lazy-missing-info :text="$t('missing.event.search')" class="flex-grow-1" vertical no-spacing :to="$resultastic('events.create')"
													   :button="$t('action.event.create')">
									</lazy-missing-info>
								</template>
								<template #missing>
									<lazy-missing-info :text="$t('missing.event.index')" class="flex-grow-1" vertical no-spacing :to="$resultastic('events.create')"
													   :button="$t('action.event.create')">
									</lazy-missing-info>
								</template>
							</data-loader>
						</responsive-floating-card>

					</div>

				</container>
			</page-content>

			<slide-sheet v-model="isEventDetailsVisible" :width="420">
				<template #default>
					<event-card-skeleton v-if="eventDetailsPending" flat no-spacing :width="420"/>
					<event-card v-else-if="eventDetails" :event="eventDetails" flat no-spacing :width="420"/>
				</template>
				<template v-if="eventDetails" #footer>
					<btn :color="eventDetails.color" large text tile class="w-full border-t"
						 :to="$app('events-event', {params: {event: eventDetails._key}, state: {event_ts: eventDetails.cache_buster}})">
						<span>{{ $t('action.to_event') }}</span>
						<icon :icon="icons.next"/>
					</btn>
				</template>
			</slide-sheet>

		</app-content>
	</div>
</template>

<script setup lang="ts">
import type {Country, SportTypePreview} from "@spoferan/spoferan-ts-core";
import {EventAttendanceMode, EventType} from "@spoferan/spoferan-ts-core";
import {backIcon, closeIcon, nextIcon, resetIcon, searchIcon} from "@spoferan/nuxt-spoferan/icons";
import {mdiSort} from "@mdi/js";
import {useIndexStore} from "../../store";

const indexStore = useIndexStore();
const {$apiFetch, $web, $auth, $date} = useNuxtApp();
const config = useRuntimeConfig();
const {t} = useI18n();
const route = useRoute();

// The filter data that can be modified by the user
const filterData = ref({
	order: route.query.order ?? 'date',
	search: route.query.search,
	sport: route.query.sport,
	format: route.query.format,
	country: route.query.country,
	state: route.query.state,
	mode: route.query.mode,
	min_date: route.query.min_date,
	max_date: route.query.max_date,
});

const responses = await Promise.all([
	useApiFetch('/v1/sports/options', {
		guest: true,
		params: {
			with_competitions: true
		}
	}),
	filterData.value.sport ? useApiFetch(`/v1/sports/${filterData.value.sport}/formats/options`, {
		guest: true
	}) : Promise.resolve({data: []}),
	useApiFetch('/v1/countries/options', {
		guest: true,
		params: {
			with_competitions: true
		}
	}),
	filterData.value.country ? useApiFetch(`/v1/countries/${filterData.value.country}/states/options`, {
		guest: true,
		params: {
			with_competitions: true
		}
	}) : Promise.resolve({data: []}),
]);

// Sport type filter
const sportTypes: SportTypePreview[] = responses[0].data;
const initiallySelectedSportType = filterData.value.sport ? sportTypes.find(sport => sport._key === filterData.value.sport) : null;
if (initiallySelectedSportType && responses[1].data.length) {
	initiallySelectedSportType.formats = responses[1].data;
}

// Region filter
const countries: Country[] = responses[2].data;
const initiallySelectedCountry = filterData.value.country ? countries.find(country => country._key === filterData.value.country) : null;
if (initiallySelectedCountry && responses[3].data.length) {
	initiallySelectedCountry.states = responses[3].data;
}

const displayEventTypes = [EventType.Event];

const {
	deviceCoordinates,
	mapModels,
	mapZoom,
	showInRow,
	inputFilterData,
	mapBounds,
	showMapFullScreen,
	mapMarkerGroups,
	filterExists,
	mapElement,
	floatingListHeight,
	floatingListAnchors,
	floatingListMinHeight,
	handleSearchInputFocus,
	handleSearchInputBlur,
	handleSubmittedFilter,
	handleOpenedFilter,
	resetFilter,
	focusMarkerGroup,
	handleApplyingFilter,
	refreshDeviceCoordinates,
	focusMap
} = useMapMainModelSearchPage('/v2/events/map', filterData, {
	type: displayEventTypes
});

const selectedDateRange = ref([filterData.value.min_date, filterData.value.max_date]);
watch(selectedDateRange, (newValue) => {
	inputFilterData.value.min_date = newValue[0];
	inputFilterData.value.max_date = newValue[1];
});
watch([() => filterData.value.min_date, () => filterData.value.max_date], () => {
	selectedDateRange.value = [filterData.value.min_date, filterData.value.max_date];
});

const selectedRegionInput = ref([filterData.value.country, filterData.value.state]);
watch(selectedRegionInput, (region) => {
	inputFilterData.value.country = region[0];
	inputFilterData.value.state = region[1];
});

const selectedSportTypeInput = ref([filterData.value.sport, filterData.value.format]);
watch(selectedSportTypeInput, (sportType) => {
	inputFilterData.value.sport = sportType[0];
	inputFilterData.value.format = sportType[1];
});

const selectedSportsText = computed(() => {
	const selectedSportType = filterData.value.sport ? sportTypes.find(sport => sport._key === filterData.value.sport) : null;
	if (!selectedSportType) {
		return null;
	}

	const selectedSportTypeFormat = filterData.value.format ? selectedSportType.formats.find(category => category._key === filterData.value.format) : null;

	return selectedSportType.title + (selectedSportTypeFormat ? `: ${selectedSportTypeFormat.title}` : '');
});

const selectedLocationText = computed(() => {
	const selectedCountry = filterData.value.country ? countries.find(country => country._key === filterData.value.country) : null;
	if (!selectedCountry) {
		return null;
	}

	const selectedCountryState = filterData.value.state ? selectedCountry.states.find(countryState => countryState._key === filterData.value.state) : null;
	if (selectedCountryState) {
		return `${selectedCountryState.locale_name}`
	}

	return selectedCountry.locale_name;
});

const filterPageTitle = computed(() => {
	const parts = [selectedSportsText.value ? `${selectedSportsText.value}-${t('label.competitions')}` : (selectedLocationText.value ? t('label.competitions') : null), selectedLocationText.value].filter(value => !!value);

	return parts.length ? parts.join(` in `) : null;
});

const listHeaderDescription = computed(() => {
	if (selectedSportsText.value && selectedLocationText.value) {
		return t('view.events.competition_calender.description_location_sport', {sport: selectedSportsText.value, location: selectedLocationText.value});
	}
	if (selectedSportsText.value) {
		return t('view.events.competition_calender.description_sport', {sport: selectedSportsText.value});
	}
	if (selectedLocationText.value) {
		return t('view.events.competition_calender.description_location', {location: selectedLocationText.value});
	}

	return t('view.events.competition_calender.description')
});

const sortOptions = [
	{
		text: t('label.distance'),
		value: 'distance'
	},
	{
		text: t('label.date'),
		value: 'date'
	}
];

const eventAttendanceModeOptions = [{
	text: t('label.any'),
	value: undefined
}, ...[EventAttendanceMode.Attendance, EventAttendanceMode.Virtual].map(attendanceMode => {
	return {
		text: t(`enum.event_attendance_mode.${attendanceMode}.title`),
		value: attendanceMode
	}
})];

const icons = {
	sort: mdiSort,
	search: searchIcon,
	reset: resetIcon,
	next: nextIcon,
	back: backIcon,
	close: closeIcon
};

// The filter data to send with every request the user can not modify
const params = computed(() => {
	const params = {
		limit: 20,
		type: displayEventTypes,
		relations: ['sportTypes', 'labels'],
		lat: undefined as string | undefined,
		lng: undefined as string | undefined
	};

	if (isSortingByDistance.value) {
		const coordinates = {
			lat: deviceCoordinates.value?.lat ?? null,
			lng: deviceCoordinates.value?.lng ?? null,
		};

		// We round the decimal places to improve caching
		params.lat = coordinates.lat ? parseFloat(coordinates.lat).toFixed(1) : undefined;
		params.lng = coordinates.lng ? parseFloat(coordinates.lng).toFixed(1) : undefined;
	}

	return params;
});

const isSortingByDistance = computed(() => {
	return filterData.value.order === 'distance';
});

const dateText = computed(() => {
	if (!filterData.value.min_date && !filterData.value.max_date) {
		return;
	}

	if (filterData.value.min_date && !filterData.value.max_date) {
		return t('conjunction.starting_at') + ' ' + $date(filterData.value.min_date).format('DD. MMM YYYY');
	}
	if (filterData.value.max_date && !filterData.value.min_date) {
		return t('conjunction.until') + ' ' + $date(filterData.value.max_date).format('DD. MMM YYYY');
	}

	return $date(filterData.value.min_date).formatDateRange(filterData.value.max_date);
});

const advancedText = computed(() => {
	const parts = [];
	if (filterData.value.mode) {
		parts.push(eventAttendanceModeOptions.find(option => option.value === filterData.value.mode)?.text)
	}

	const validParts = parts.filter(part => !!part);

	return validParts.length ? validParts.join(', ') : null;
});

const selectedSort = computed(() => {
	return sortOptions.find(option => option.value === filterData.value.order);
});


function switchSort() {
	const selectedSortOptionIndex = filterData.value.order ? sortOptions.findIndex(option => option.value === filterData.value.order) : -1;
	if (selectedSortOptionIndex > -1 && selectedSortOptionIndex < sortOptions.length - 1) {
		filterData.value.order = sortOptions[selectedSortOptionIndex + 1].value;
	} else {
		filterData.value.order = sortOptions[0].value;
	}
}

const isEventDetailsVisible = ref(false);
const eventDetails = ref(null);
const eventDetailsPending = ref(false);

async function showEventDetails(mapEvent) {
	eventDetails.value = mapEvent;
	eventDetailsPending.value = true;
	isEventDetailsVisible.value = true;

	$apiFetch(`/v1/events/map/${mapEvent.id}`, {
		guest: true,
		params: {
			ts: mapEvent.cache_buster
		}
	}).then(response => {
		eventDetails.value = response.data;
	}).finally(() => {
		eventDetailsPending.value = false;
	})
}

async function loadSportTypeFormats(sportType) {
	if (!sportType || !sportType.formats_count) {
		sportType.formats = [];
		return;
	}

	const {data} = await $apiFetch(`/v1/sports/${sportType._key}/formats/options`, {
		guest: true,
		params: {
			ts: sportType.cache_buster
		}
	});
	sportType.formats = data;
}

async function loadCountryStates(country) {
	if (!country || !country.states_count) {
		country.states = []
		return;
	}

	const {data} = await $apiFetch(`/v1/countries/${country._key}/states/options`, {
		guest: true,
		params: {
			with_competitions: true
		}
	});
	country.states = data
}

watch(isSortingByDistance, (isSortingByDistance) => {
	if (isSortingByDistance) {
		refreshDeviceCoordinates();
	}
});

onMounted(() => {
	nextTick(() => {
		// We need to wait for the map to be mounted
		if (isSortingByDistance) {
			refreshDeviceCoordinates();
		}
	})
})

useMeta({
	title: filterPageTitle.value ?? t('view.events.competition_calender.title'),
	description: listHeaderDescription.value,
	canonicalQueries: Object.keys(filterData.value)
});

useSchemaOrg([
	defineWebPage({
		'@type': ['CollectionPage', 'SearchResultsPage']
	}),
])
</script>

